import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { CommandBarClientSDK } from "commandbar"; // use snippet installation to force prototype URL for call to /latest

const endUserSlug = "pied-piper-user";

const App = () => {
  React.useEffect(() => {
    const userSlug = localStorage.getItem("end-user-slug") ?? endUserSlug;
    (window.CommandBar as CommandBarClientSDK).boot(userSlug);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          defaults to PiedPiper org: <code>d978e9df</code>
          <br />
          <code>localStorage.setItem('orgid', 'xxx')</code>
          <br />
          <br />
          defaults to End-user slug: <code>{endUserSlug}</code>
          <br />
          <code>localStorage.setItem('end-user-slug', 'xxx')</code> (Set to
          empty string to boot anonymous user)
          <br />
          <br />
          <b>
            Refresh page after changing <code>localStorage</code> keys!
          </b>
        </p>
      </header>
    </div>
  );
};

export default App;
